import ApplicationController from "../../application_controller";

export default class AwaitingConsultationController extends ApplicationController {
    connect() {
        super.connect();
    }

    updateQuantityOptions(event) {
        const selectElement = event.target;
        const quantitySelectElement = document.getElementById('cp_spree_order_subscribed_quantity');
        const selectedOption = selectElement.options[selectElement.selectedIndex];
        const options = [24,30];

        quantitySelectElement.innerHTML = '';

        options.forEach((option) => {
            const optionElement = this.createOptionElement(option);
            quantitySelectElement.appendChild(optionElement);
        });
    }

    createOptionElement(value) {
        const optionElement = document.createElement('option');
        optionElement.text = `${value} doses / month`;
        optionElement.value = value;

        if (parseInt(this.requestedQuantity()) === value) {
            optionElement.setAttribute('selected', 'true');
        }

        return optionElement;
    }

    requestedQuantity() {
        try {
            return document.querySelector('div[data-requested-quantity]').dataset.requestedQuantity
        } catch (e) {
            return 0;
        }
    }
}
