import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

export default class extends Controller {
  TIMEOUT = 0;

  async connect() {
    StimulusReflex.register(this);
  }

  removeToasts() {
    const toasts = document.querySelectorAll('.toastify');

    if (toasts.length > 0) {
      toasts.forEach((toast) => toast.classList.remove('on'));
    }
  }

  showToast({ text, type = 'info', duration = 1000 }) {
    // Removes toasts from the DOM to prevent stacking
    this.removeToasts();

    Toastify({
      text,
      duration,
      close: true,
      gravity: 'top',
      position: 'center',
      stopOnFocus: true,
      style: {
        background:
          type === 'info'
            ? 'linear-gradient(to right,  #0859A6, #0971D3)'
            : '#D41111',
      },
    }).showToast();
  }

  reflexSuccess(_, reflex) {
    if (reflex === 'Admin::Crm::AssignRepModalReflex#confirm') {
      this.showToast({
        text: 'Sales rep successfully assigned!',
      });
    }
  }

  reflexError() {
    this.showToast({
      text: "There was an error processing your request. Please refresh the page to try again or reach out to customerservice@chaimhealth.com for assistance.",
      type: 'error',
      duration: -1,
    });
  }

  // eslint-disable-next-line consistent-return
  callStimulusAction({ maxRetries = 30, cb }) {
    clearTimeout(this.TIMEOUT);

    // If action cable connection is open, call the reflex action
    if (document.body.classList.contains('stimulus-reflex-connected')) {
      try {
        cb();
      } catch (error) {
        if (maxRetries <= 0) {
          // Clear timeout to ensure it's ready for another called reflex
          return this.showToast({
            text: "There was an error processing your request. Please refresh the page to try again or reach out to customerservice@chaimhealth.com for assistance.",
            type: 'error',
            duration: -1,
          });
        }
        // If there is an error, retry reflex action
        this.TIMEOUT = setTimeout(() => {
          this.showToast({
            text: `There was an issue. Retrying request ${maxRetries} more times`,
          });
          this.callStimulusAction({ maxRetries: maxRetries - 1, cb });
        }, 1000);
      }
    } else {
      // If action cable connection is closed, retry reflex action
      this.TIMEOUT = setTimeout(() => {
        this.callStimulusAction({ maxRetries: maxRetries - 1, cb });
      }, 1000);
    }
  }
}
