import moment from 'moment';
import ApplicationController from './application_controller';
import { debounce } from './utilities/debounce';

export default class extends ApplicationController {
  static targets = ['gender', 'dob', 'phoneNum', 'state', 'submit', 'arrow'];

  validStates = this.stateTarget.dataset.validStates;

  afterValidatePhoneNumber() {
    this.phoneNumTarget.getAttribute('valid') === 'true' ? this.enableSubmitButton() : this.disableSubmitButton();
  }

  connect() {
    super.connect();
    this.addEventListeners();
    this.disableSubmitButton();
    this.genderTargets.forEach((el) => {
      el.closest('label').classList.remove('bg-link-active', 'text-white');
      if (el.checked) el.closest('label').classList.add('bg-link-active', 'text-white');
    });
  }

  disconnect() {
    super.disconnect();
    this.removeEventListeners();
  }

  addEventListeners() {
    this.genderHandler = debounce(this.validateGender.bind(this), 50);
    this.dobHandler = this.validateDob.bind(this);
    this.phoneNumHandler = this.validatePhoneNumber.bind(this);
    this.stateHandler = this.validateState.bind(this);

    this.genderTargets.forEach((el) => {
      el.addEventListener('change', this.genderHandler);
      el.addEventListener('input', this.genderHandler);
    });
    this.dobTarget.addEventListener('change', this.dobHandler);
    this.dobTarget.addEventListener('input', this.dobHandler);
    this.phoneNumTarget.addEventListener('change', this.phoneNumHandler);
    this.phoneNumTarget.addEventListener('input', this.phoneNumHandler);
    this.stateTarget.addEventListener('change', this.stateHandler);
    this.stateTarget.addEventListener('input', this.stateHandler);
  }

  removeEventListeners() {
    this.genderTargets.forEach((el) => {
      el.removeEventListener('change', this.genderHandler);
      el.removeEventListener('input', this.genderHandler);
    });
    this.dobTarget.removeEventListener('change', this.dobHandler);
    this.dobTarget.removeEventListener('input', this.dobHandler);
    this.phoneNumTarget.removeEventListener('change', this.phoneNumHandler);
    this.phoneNumTarget.removeEventListener('input', this.phoneNumHandler);
    this.stateTarget.removeEventListener('change', this.stateHandler);
    this.stateTarget.removeEventListener('input', this.stateHandler);
  }

  validateGender(event) {
    this.genderTargets.forEach((el) => el.closest('label').classList.remove('bg-link-active', 'text-white'));
    event.target.closest('label').classList.add('bg-link-active', 'text-white');

    if (event.target.value.toLowerCase() === 'male') {
      this.genderTargets[0].setAttribute('valid', true);
      this.enableSubmitButton();
    } else {
      this.genderTargets[0].setAttribute('valid', false);
      this.disableSubmitButton();

      this.callStimulusAction({
        cb: () => {
          this.stimulate('UserDemographics::Ineligible#set_ineligible_details', 'gender');
        },
      });
    }
  }

  validateDob(_event) {
    if (this.dobTarget.value.length < 10) { // "MM/DD/YYYY"
      this.dobTarget.setAttribute('valid', false);
      this.disableSubmitButton();
      return;
    }

    if (this.isValidDOB()) {
      this.dobTarget.setAttribute('valid', true);
      this.enableSubmitButton();
    } else {
      this.dobTarget.setAttribute('valid', false);
      this.disableSubmitButton();
      this.callStimulusAction({
        cb: () => {
          this.stimulate('UserDemographics::Ineligible#set_ineligible_details', 'dob');
        },
      });
    }
  }

  validatePhoneNumber(_event) {
    if (this.phoneNumTarget.value.length < 14) { // "(XXX) XXX-XXXX"
      this.phoneNumTarget.setAttribute('valid', false);
      this.disableSubmitButton();
    } else {
      this.callStimulusAction({
        cb: () => {
          this.stimulate('UserDemographics::Ineligible#validate_phone_number', this.phoneNumTarget.value);
        },
      });
    }
  }

  validateState(_event) {
    if (!this.stateTarget.value) return;

    if (this.validStates.includes(this.stateTarget.value)) {
      this.stateTarget.setAttribute('valid', true);
      this.enableSubmitButton();
    } else {
      this.stateTarget.setAttribute('valid', false);
      this.disableSubmitButton();
      this.callStimulusAction({
        cb: () => {
          this.stimulate('UserDemographics::Ineligible#set_ineligible_details', 'state');
        },
      });
    }
  }

  isValidDOB() {
    const currentYear = moment().year();
    const birthYear = moment(this.dobTarget.value, 'MM/DD/YYYY').year();
    const age = currentYear - birthYear;
    return age >= 18 && (birthYear >= currentYear - 120);
  }

  disableSubmitButton() {
    if (this.submitTarget.hasAttribute('disabled')) return;
    if (this.allFieldsValid()) return;

    this.submitTarget.setAttribute('disabled', true);
    this.arrowTarget.setAttribute('src', this.arrowTarget.dataset.rightArrowInactive);
    this.arrowTarget.classList.remove('hover:cursor-pointer');
    this.arrowTarget.classList.add('hover:cursor-not-allowed');
  }

  enableSubmitButton() {
    if (!this.submitTarget.hasAttribute('disabled')) return;
    if (!this.allFieldsValid()) return;

    this.submitTarget.removeAttribute('disabled');
    this.arrowTarget.setAttribute('src', this.arrowTarget.dataset.rightArrowActive);
    this.arrowTarget.classList.add('hover:cursor-pointer');
    this.arrowTarget.classList.remove('hover:cursor-not-allowed');
  }

  allFieldsValid() {
    const fields = [this.genderTargets[0], this.dobTarget, this.phoneNumTarget, this.stateTarget];
    return fields.every((field) => field.getAttribute('valid') === 'true');
  }
}
